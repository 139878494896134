import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91')
];

export const server_loads = [2,8,9,10,11,12,13,14,4,5,6];

export const dictionary = {
		"/(app)": [16,[2],[3]],
		"/(app)/admin": [45,[2,7],[3]],
		"/(app)/admin/cli-policies": [~46,[2,7],[3]],
		"/(app)/admin/cli-policies/[policyID=integer]": [~47,[2,7,8],[3]],
		"/(app)/admin/cli-policies/[policyID=integer]/licenses": [~48,[2,7,8],[3]],
		"/(app)/admin/org": [~49,[2,7],[3]],
		"/(app)/admin/org/[org]/policies": [~50,[2,7,9],[3]],
		"/(app)/admin/org/[org]/policies/[id]": [~51,[2,7,9],[3]],
		"/(app)/admin/org/[org]/services": [~52,[2,7,9],[3]],
		"/(app)/admin/org/[org]/users": [~53,[2,7,9],[3]],
		"/(app)/admin/org/[org]/users/new": [54,[2,7,9],[3]],
		"/(app)/admin/services": [~55,[2,7],[3]],
		"/(app)/admin/settings": [56,[2,7],[3]],
		"/(app)/admin/users": [57,[2,7],[3]],
		"/(app)/admin/users/[user]": [~58,[2,7],[3]],
		"/auth/cli": [~64],
		"/auth/cli/success": [65],
		"/auth/email-sent": [66],
		"/auth/login": [~67],
		"/auth/login/password": [68],
		"/auth/login/set-password": [69],
		"/auth/login/signup": [~70],
		"/auth/logout": [~71],
		"/auth/reset-password": [72],
		"/auth/reset-password/reset": [73],
		"/(app)/org/[org]": [~59,[2,10],[3]],
		"/(app)/org/[org]/policies": [60,[2,10],[3]],
		"/(app)/org/[org]/services": [61,[2,10],[3]],
		"/privacy-policy": [74],
		"/public": [~75],
		"/public/[service]": [76,[11]],
		"/public/[service]/animals": [~77,[11,12]],
		"/public/[service]/animals/[id]/(tabs)": [78,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/dna-samples": [~79,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/ebv": [80,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/genetic-tests": [81,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/performance": [~82,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/progeny": [83,[11,12,13]],
		"/public/[service]/catalogs": [~84,[11]],
		"/public/[service]/catalogs/[catalogID]": [85,[11,14]],
		"/public/[service]/catalogs/[catalogID]/ebvs": [~86,[11,14]],
		"/public/[service]/ebv/references/[id]": [~87,[11]],
		"/public/[service]/help/ebv": [88,[11,15]],
		"/public/[service]/members": [89,[11]],
		"/public/[service]/sale-catalogs": [~90,[11]],
		"/(app)/settings": [~62,[2],[3]],
		"/(app)/settings/cli": [~63,[2],[3]],
		"/terms-and-conditions": [91],
		"/(app)/[service]": [17,[2,4],[3]],
		"/(app)/[service]/accounting/invoices": [~19,[2,4],[3]],
		"/(app)/[service]/account": [18,[2,4],[3]],
		"/(app)/[service]/animals": [~20,[2,4],[3]],
		"/(app)/[service]/animals/[id]": [21,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/dna-samples": [22,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/ebv": [23,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/genetic-tests": [24,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/performance": [25,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/progeny": [26,[2,4,5],[3]],
		"/(app)/[service]/disposals": [~27,[2,4],[3]],
		"/(app)/[service]/disposals/[id]": [28,[2,4],[3]],
		"/(app)/[service]/matings": [29,[2,4],[3]],
		"/(app)/[service]/matings/[id]": [30,[2,4],[3]],
		"/(app)/[service]/registrations": [31,[2,4],[3]],
		"/(app)/[service]/registrations/[id]": [32,[2,4],[3]],
		"/(app)/[service]/sale-catalogs": [33,[2,4],[3]],
		"/(app)/[service]/sale-catalogs/[catalogID]": [34,[2,4],[3]],
		"/(app)/[service]/test-requests": [~35,[2,4],[3]],
		"/(app)/[service]/test-requests/[requestID]": [36,[2,4,6],[3]],
		"/(app)/[service]/test-requests/[requestID]/genetic-tests": [37,[2,4,6],[3]],
		"/(app)/[service]/test-requests/[requestID]/genotype-samples": [38,[2,4,6],[3]],
		"/(app)/[service]/test-requests/[requestID]/mbv": [~39,[2,4,6],[3]],
		"/(app)/[service]/test-requests/[requestID]/parentage": [40,[2,4,6],[3]],
		"/(app)/[service]/transfers": [41,[2,4],[3]],
		"/(app)/[service]/transfers/[id]": [42,[2,4],[3]],
		"/(app)/[service]/upload": [43,[2,4],[3]],
		"/(app)/[service]/upload/[uploadID]": [44,[2,4],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';